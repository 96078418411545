import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import clsx from 'clsx';

import styles from '../styles/breadcrumbs.module.scss';

const Breadcrumbs = ({ className, crumbs }) => (
	<nav
		className={clsx(styles.breadcrumbs, className)}
		aria-label='Breadcrumb'
	>
		<ol
			vocab='https://schema.org/'
			typeof='BreadcrumbList'
		>
			{crumbs && crumbs.map((x, i) => {
				const Name = () => (
					<span
						property='name'
						className={styles.name}
					>
						{x.name}
					</span>
				);

				const Item = () => {
					if (x.to) {
						return (
							<Link
								property='item'
								typeof='WebPage'
								to={x.to}
							>
								<Name />
							</Link>
						);
					}

					return (
						<Name />
					);
				};

				const position = (i + 1);

				return (
					<li
						key={i}
						property='itemListElement'
						typeof='ListItem'
					>
						<Item />
						<meta
							property='position'
							content={position}
						/>
					</li>
				);
			})}
		</ol>
	</nav>
);

Breadcrumbs.props = {
	className: PropTypes.string,
	crumbs: PropTypes.arrayOf(PropTypes.object)
};

export default Breadcrumbs;