import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Button } from '@material-ui/core';
import clsx from 'clsx';

import SEO from '../components/seo';
import Breadcrumbs from '../components/breadcrumbs';
import PageHeader from '../components/page-header';
import ProductGrid from '../components/product-grid';

import styles from '../styles/partner.module.scss';

const PageTitleHeader = (props) => (
	<h2>{props.children}</h2>
);

const Partner = ({ data }) => {
	const vendor = data.vendor.edges[0].node;
	const products = data.products.edges;

	return (
		<>
			<SEO
				title={vendor.name}
				description={vendor.description}
				image={vendor.logo.childImageSharp.main.src}
				path={`/our-partners/${vendor.handle}/`}
			/>
			<PageHeader
				title='Our Partners'
				to='/our-partners/'
				titleComponent={PageTitleHeader}
			/>
			<div className={styles.main}>
				<div className={styles.content}>
					<section className={styles.top}>
						<Breadcrumbs
							crumbs={[
								{
									name: 'Our Partners',
									to: '/our-partners/'
								},
								{
									name: vendor.name
								}
							]}
						/>
					</section>
					<section className={styles.vendor}>
						<Img
							className={clsx(styles.logo, vendor.logoSize === 'small' ? styles.small : styles.large)}
							fluid={vendor.logo.childImageSharp.main}
							alt={vendor.name}
							objectFit='contain'
							imgStyle={{
								objectFit: 'contain'
							}}
						/>
						<h1>{vendor.name}</h1>
						<p>{vendor.description}</p>
						{vendor.website &&
							<p><strong>Website:</strong> <a href={`${vendor.website}?utm_source=roselium&utm_referrer=roselium`} target='_blank' rel='noopener noreferrer'>{vendor.website}</a></p>
						}
					</section>
					{Boolean(products.length) &&
						<section className={styles.products}>
							<h5>Shop Products by <br /> <Link to={`/store/brand/${vendor.handle}/`}>{vendor.name}</Link></h5>
							<ProductGrid products={products} />
							<Button
								className={styles.viewstore}
								variant='outlined'
								color='primary'
								fullWidth={true}
								component={Link}
								to='/store/all/'
							>
								VIEW MORE IN OUR STORE
                            </Button>
						</section>
					}
				</div>
			</div>
		</>
	)

};

export const query = graphql`
    query($name: String!, $handle: String!) {
        vendor: allVendorsJson(
            filter: {
                handle: { eq: $handle }
            }            
        ) {
            edges {
                node {
                    name
                    handle
                    logo {
                        childImageSharp {
                            main: fluid(quality: 90, maxWidth: 1440) {
                                ...GatsbyImageSharpFluid_tracedSVG
                            }
                        }
					}
					logoSize
					description
					website
                }
            }
        }
        
        products: allShopifyProduct(
            filter: {
                vendor: { eq: $name }
            }
        ) {
            edges {
                node {
                    id
                    title
                    handle
                    productType
                    shopifyId
                    createdAt
                    images {
                        id
                        originalSrc
                        localFile {
                            childImageSharp {
                                fluid(quality: 90, maxWidth: 680) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                        }
                    }
                    variants {
                        shopifyId
                        price
                    }
                }
            }
        }
    }
`;

export default Partner;